import FirebaseFirestoreService from "../libs/firebase/FirebaseFirestoreService";
import FirebaseStorageService from "../libs/firebase/FirebaseStorageService";
import FirebaseFirestore from "@firebase/firestore-types";
import { EEventKind, TContact, TDocument, TEvent } from "./types";
import { TQuery, TQueryDocument } from "@libs/firebase/Firebase.types";
import { ActionFunctionArgs } from "react-router-dom";
import { dateToString, getMonthName } from "utils/utils";
import { TEventFormValues } from "components/events/EventForm";

const COLLECTION = "events";

export const countFiles = async (eventId: string) => {
  const queries = [
    {
      field: "eventId",
      condition: "==" as FirebaseFirestore.WhereFilterOp,
      value: eventId,
    },
  ];
  return await FirebaseFirestoreService.countDocuments("uploads", queries);
};

export const countVideos = async (eventId: string) => {
  const queries = [
    {
      field: "eventId",
      condition: "==" as FirebaseFirestore.WhereFilterOp,
      value: eventId,
    },
    {
      field: "status",
      condition: "==" as FirebaseFirestore.WhereFilterOp,
      value: "video_to_storage",
    },
  ];
  return await FirebaseFirestoreService.countDocuments("videos", queries);
};

export const getEvents = async ({ request }: ActionFunctionArgs) => {
  let queries = [];
  let perPage = undefined;
  const url = new URL(request.url);
  const searchTerm = url.searchParams.get("search_term");

  if (searchTerm) {
    const searchTermLowerCaseWithoutAccents = removeAccents(
      searchTerm as string
    );

    queries.push({
      field: "searchIndex",
      condition: "array-contains" as FirebaseFirestore.WhereFilterOp,
      value: searchTermLowerCaseWithoutAccents,
    });
  } else {
    const today = dateToString(new Date()) as string;
    queries.push({
      field: "date",
      condition: ">=" as FirebaseFirestore.WhereFilterOp,
      value: today,
    });
    perPage = 50;
  }

  const args: TQueryDocument = {
    collection: COLLECTION,
    orderByField: "date",
    orderByDirection: "asc",
    queries: queries,
    perPage: perPage,
  };
  const response = await FirebaseFirestoreService.readDocuments(args);

  const events = response.docs.map((doc: TDocument) => {
    return {
      id: doc.id,
      ...doc.data(),
    };
  });

  const eventsWithCount = await Promise.all(
    events.map(async (event) => {
      if (event.kind === EEventKind.Uploader) {
        const filesCount = await countFiles(event.id);
        return { ...event, filesCount, videosCount: undefined };
      }

      const videosCount = await countVideos(event.id);

      return { ...event, filesCount: undefined, videosCount };
    })
  );

  return eventsWithCount;
};

export const searchEvents = async ({ request }: ActionFunctionArgs) => {
  const data = Object.fromEntries(await request.formData());
  const { search_term } = data;

  const args: TQueryDocument = {
    collection: COLLECTION,
    orderByField: "date",
    orderByDirection: "desc",
    queries: [
      {
        field: "searchIndex",
        condition: "array-contains",
        value: search_term as string,
      },
    ],
  };
  const response = await FirebaseFirestoreService.readDocuments(args);

  const events = response.docs.map((doc: TDocument) => {
    return {
      id: doc.id,
      ...doc.data(),
    };
  });

  return events;
};

export const createEvent = async (data: TEventFormValues): Promise<string> => {
  const code = await generateRandomCode();

  const {
    title,
    kind,
    date,
    releaseDate,
    languages = ["pt-BR", "en-US"],
    city = "",
    state = "",
    country = "",
    totalGuests = "",
    owners: ownersList = [],
    contacts = [],
    customization,
  } = data;

  const theme = customization?.theme || { name: "rose" };
  const template = customization?.template || {
    name: "wedding-rose",
    titleFontSize: "",
    titleFontFamily: "default",
    titleOneText: title,
    titleTwoText: "",
    subtitleText: "",
  };

  // from array of objects to string array
  const owners = ownersList.map((owner) => owner.email);

  const searchIndex = generateSearchIndex({
    title,
    code,
    date,
    city,
    state,
    country,
    contacts,
  });

  const event: TEvent = {
    code,
    title,
    kind,
    date,
    releaseDate,
    contacts,
    owners,
    city,
    state,
    country,
    totalGuests,
    languages: removeNullValues(languages),
    customization: {
      theme: getTheme(theme.name),
      template,
    },
    searchIndex: searchIndex,
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
  };

  const response = await FirebaseFirestoreService.createDocument(
    COLLECTION,
    event
  );

  return response.id;
};

export const updateEvent = async (id: string, data: TEventFormValues) => {
  const {
    code,
    title,
    kind,
    date,
    releaseDate,
    languages,
    city,
    state,
    bannerUrl,
    country,
    totalGuests,
    owners: ownersList,
    contacts,
    customization: {
      theme: { name: themeName },
      template,
    },
  } = data;

  const searchIndex = generateSearchIndex({
    title,
    code,
    date,
    city,
    state,
    country,
    contacts,
  });

  const event: TEvent = {
    title,
    kind: kind as EEventKind,
    date,
    releaseDate,
    languages: removeNullValues(languages),
    city,
    state,
    country,
    totalGuests,
    contacts,
    customization: {
      theme: getTheme(themeName),
      template,
    },
    searchIndex: searchIndex,
    updatedAt: new Date().toISOString(),
  };

  // from array of objects to string array
  const owners = ownersList?.map((owner) => owner.email);

  if (bannerUrl) {
    event.bannerUrl = bannerUrl;
  }

  if (owners) {
    event.owners = owners;
  }

  await FirebaseFirestoreService.updateDocument(
    COLLECTION,
    id as string,
    event
  );

  return id;
};

export const uploadEventBanner = async (
  event: TEvent,
  file: Blob | Uint8Array | ArrayBuffer,
  setUploadProgress: (arg0: number) => void
): Promise<string> => {
  const downloadUrl = await FirebaseStorageService.uploadFile(
    file,
    `events/${event?.id}-${Date.now()}.png`,
    setUploadProgress
  );

  return downloadUrl as string;
};

export const updateEventBanner = async ({
  eventId,
  bannerUrl,
}: {
  eventId: string;
  bannerUrl: string;
}) => {
  try {
    const response = await FirebaseFirestoreService.readDocument(
      COLLECTION,
      eventId
    );
    const currentBannerUrl = response.data()?.bannerUrl;

    if (currentBannerUrl && currentBannerUrl !== bannerUrl) {
      await FirebaseStorageService.deleteFile(currentBannerUrl);
    }
    await FirebaseFirestoreService.updateDocument(COLLECTION, eventId, {
      bannerUrl,
    });
  } catch (error) {
    console.log("error:", JSON.stringify(error));
  }
};

export const getEventById = async ({ params }: ActionFunctionArgs) => {
  const { id } = params;
  const response = await FirebaseFirestoreService.readDocument(
    COLLECTION,
    id as string
  );
  const event = {
    id: response.id,
    ...response.data(),
  };
  return event;
};

export const getEventByCode = async (code: string) => {
  const response = await FirebaseFirestoreService.readDocuments({
    collection: COLLECTION,
    queries: [
      {
        field: "code",
        condition: "==",
        value: code,
      },
    ],
    perPage: 1,
    orderByField: "code",
  });

  if (response.docs.length === 0) return null;

  const firstDoc = response.docs[0];

  const event = {
    id: firstDoc.id,
    ...firstDoc.data(),
  };

  return event;
};

export const generateEventStats = async (year: number) => {
  const queries: TQuery[] = [
    {
      field: "createdAt",
      condition: ">=",
      value: `${year}-01-01`,
    },
    {
      field: "createdAt",
      condition: "<=",
      value: `${year}-12-31`,
    },
  ];
  const response = await FirebaseFirestoreService.readDocuments({
    collection: COLLECTION,
    queries,
    perPage: 1000,
  });
  const events = response.docs.map((doc: TDocument) => {
    return {
      id: doc.id,
      ...doc.data(),
    };
  });

  // clean up events with test and duplicated data
  const validEvents = events.filter(
    ({ title }: TEvent) => !title.match(/demo|descartar|duplicado|teste(s)?/i)
  );

  const months = new Array(12).fill(0);

  const salesCount = validEvents.reduce(
    (acc: Record<string, number>, event: TEvent) => {
      if (!event.createdAt) return acc;

      const monthIndex = new Date(event.createdAt).getMonth();
      acc[monthIndex] += 1;

      return acc;
    },
    [...months]
  );

  const eventsCount = validEvents.reduce(
    (acc: Record<string, number>, event: TEvent) => {
      if (!event.date) return acc;

      const date = new Date(event.date);

      if (date.getFullYear() !== year) return acc;

      const monthIndex = date.getMonth();

      acc[monthIndex] += 1;

      return acc;
    },
    [...months]
  );

  const releasesCount = validEvents.reduce(
    (acc: Record<string, number>, event: TEvent) => {
      if (!event.releaseDate) return acc;

      const date = new Date(event.releaseDate);

      if (date.getFullYear() !== year) return acc;

      const monthIndex = date.getMonth();

      acc[monthIndex] += 1;

      return acc;
    },
    [...months]
  );

  return {
    salesCount,
    eventsCount,
    releasesCount,
  };
};

export const sendReleaseEmailRequest = async (eventCode: string) => {
  const body = JSON.stringify({ code: eventCode });

  await fetch(
    `https://us-central1-cdt-america.cloudfunctions.net/cdtPublicApi/v1/events/${eventCode}/sendReleaseEmail`,
    {
      method: "POST",
      body: body,
      headers: { "Content-Type": "application/json" },
    }
  );

  console.info("E-mail enviado com sucesso");
};

export const getCardLink = (
  model: string,
  event: TEvent,
  eventUrl: string,
  format: string
) => {
  const { code = "", title, date } = event;
  const { titleOneText, titleTwoText } = event.customization?.template || {};

  let eventName = titleOneText || title;

  // White template has two titles
  if (event.customization?.template?.name === "wedding-white") {
    eventName = `${titleOneText} & ${titleTwoText}`;
  }

  const eventDate = new Date(date);
  const eventDateFormatted = `${eventDate.getUTCDate()} | ${getMonthName(
    eventDate
  )} | ${eventDate.getFullYear()}`;

  const params = new URLSearchParams({
    model,
    format,
    eventCode: code,
    eventName,
    eventDate: eventDateFormatted,
    eventUrl: eventUrl,
  });

  return `https://api.capsuladotempo.com/v1/eventCard?${params.toString()}`;
};

const generateRandomCode = async (): Promise<string | undefined> => {
  try {
    const randomCode = Math.floor(100000 + Math.random() * 900000).toString();
    const response = await FirebaseFirestoreService.readDocuments({
      collection: COLLECTION,
      queries: [
        {
          field: "code",
          condition: "==",
          value: randomCode,
        },
      ],
    });
    if (response.docs.length > 0) {
      return generateRandomCode();
    } else {
      return randomCode;
    }
  } catch (error) {
    const errorMsg = `generateRandomCode [${JSON.stringify(error)}]`;
    console.log(errorMsg);
  }
};

const getTheme = (themeName: string) => {
  switch (themeName) {
    case "white":
      return {
        name: "white",
        themeColor: "#272727",
        backgroundColor: "bg-theme-white",
        cardShape: "square",
        buttonShape: "square-white",
      };
    case "rose":
      return {
        name: "rose",
        themeColor: "#F76C6F",
        backgroundColor: "bg-theme-rose",
        cardShape: "rounded",
        buttonShape: "rounded-rose",
      };
    default:
      return {
        name: "rose",
        themeColor: "#F76C6F",
        backgroundColor: "bg-theme-rose",
        cardShape: "rounded",
        buttonShape: "rounded-rose",
      };
  }
};

function generateSearchIndex(
  event: Pick<
    TEvent,
    "title" | "code" | "date" | "city" | "state" | "country" | "contacts"
  >
): string[] {
  const { title, code, date, city, state, country, contacts } = event;
  const dateSearchIndex = generateDateSearchIndex(date);
  const titleSearchIndex = generateTitleSearchIndex(title);
  const contactsSearchIndex = generateContactsSearchIndex(contacts);
  const citySearchIndex = removeAccents(city);
  const stateSearchIndex = removeAccents(state);
  const countrySearchIndex = removeAccents(country);
  const searchIndex = [
    code?.toLowerCase(),
    citySearchIndex,
    stateSearchIndex,
    countrySearchIndex,
    ...dateSearchIndex,
    ...titleSearchIndex,
    ...contactsSearchIndex,
  ];

  const searchIndexWithoutEmptyString = searchIndex.filter(
    (value) => value !== ""
  );

  const searchIndexWithoutUndefined = searchIndexWithoutEmptyString.filter(
    (value) => value !== undefined
  ) as string[];

  return searchIndexWithoutUndefined;
}

function generateDateSearchIndex(date: string) {
  // it will receive a date in the format YYYY-MM-DD
  // and will return an array with the following values:
  // [YYYY-MM-DD, YYYY-MM, YYYY, DD/MM/YYYY, MM/YYYY]
  const [year, month, day] = date.split("-");
  const dateSearchIndex = [
    date,
    `${year}-${month}`,
    year,
    `${day}/${month}/${year}`,
    `${month}/${year}`,
  ];
  return dateSearchIndex;
}

function generateContactsSearchIndex(
  contacts: TContact[] | undefined
): string[] {
  // guard clause, if contacts is empty or undefined, return empty array
  if (!contacts || contacts.length === 0) return [];
  const contactsSearchIndex = contacts.map((contact) => {
    const { name, email, whatsapp, instagram } = contact;
    // split the name into an array of words
    // get the first and last element of the array
    const nameLowerCaseWithoutAccents = removeAccents(name);
    const nameArray = nameLowerCaseWithoutAccents?.split(" ");
    const firstName = nameArray?.shift();
    const lastName = nameArray?.pop();

    return [
      name?.toLowerCase(),
      nameLowerCaseWithoutAccents,
      firstName,
      lastName,
      email?.toLowerCase(),
      whatsapp?.toLowerCase(),
      instagram?.toLowerCase(),
    ];
  });
  return contactsSearchIndex.flat() as string[];
}

function generateTitleSearchIndex(title: string) {
  // it will receive a title and will return an array with the following values:
  // [title lowercase, title without accents, and array of words with length greater than 2 in lowercase]
  const titleLowerCaseWithoutAccents = removeAccents(title);
  const titleArray = titleLowerCaseWithoutAccents
    .split(" ")
    .filter((word: string) => word.length > 2);
  const titleSearchIndex = [
    title.toLowerCase(),
    titleLowerCaseWithoutAccents,
    ...titleArray,
  ];
  return titleSearchIndex;
}

function removeAccents(str: string | undefined) {
  // guard clause, if str is empty, return empty string
  if (!str) return "";
  // it will receive a string and will return the same string without accents and lowercase
  return str
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .toLowerCase();
}

const removeNullValues = (array: Array<any>) => array.filter((value) => value);
