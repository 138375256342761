import Badge from "components/ui/Badge";
import { EEventKind, TEvent } from "../../services/types";
import React from "react";
import { Link } from "react-router-dom";
import { formatDate, getLocation } from "utils/utils";

type Props = {
  events: (TEvent & { videosCount: number; filesCount: number })[] | null;
};

export default function EventList({ events }: Props) {
  if (events?.length === 0) {
    return (
      <div className="w-full flex items-center justify-center">
        <p>Nenhum evento encontrado</p>
      </div>
    );
  }

  return (
    <div className="w-full flex flex-col items-start justify-center gap-2">
      {events?.map((event) => (
        <Link
          to={`/admin/events/${event.id}`}
          key={event.id}
          className="w-full no-underline appearance-none flex items-center justify-between border border-gray-300 p-1 max-h-20 gap-4 bg-white relative"
        >
          <div className="h-full w-[500px] flex gap-5 items-center">
            <div className="w-24 h-[70px] bg-gray-200">
              {event.bannerUrl ? (
                <img
                  className="h-full w-full object-cover"
                  src={event.bannerUrl}
                  alt={event.title}
                />
              ) : (
                <p className="h-full w-full flex items-center justify-center">
                  Sem banner
                </p>
              )}
            </div>
            <div className="flex flex-col items-start">
              <h3>{event.title}</h3>
              <p>
                {getLocation(event)}{" "}
                {event.kind === EEventKind.Uploader && (
                  <Badge className="bg-blue-600 ml-1">Uploader</Badge>
                )}
              </p>
            </div>
          </div>
          <div className="w-56 h-full flex flex-col justify-center gap-1">
            <p className="flex items-center justify-between">
              Data do evento:
              <span className="bg-rose-100 px-1 font-bold text-base">
                {formatDate(event.date)}
              </span>
            </p>
            <p className="flex items-center justify-between">
              Data de entrega:{" "}
              <span className="px-1 font-medium text-base">
                {formatDate(event.releaseDate)}
              </span>
            </p>
          </div>
          <div className="w-40 h-full flex flex-col items-start px-5 pt-3">
            <p className="">
              Código: <span>{event.code}</span>
            </p>
            <p className="">
              <>
                Arquivos:{" "}
                <span
                  className={`font-bold ${
                    event.filesCount === 0 && "bg-rose-100 px-1"
                  }`}
                >
                  {event.filesCount}
                </span>
              </>
            </p>
            {event.kind === EEventKind.Uploader && (
              <div className="w-2 h-full bg-blue-600 absolute top-0 right-0"></div>
            )}
          </div>
        </Link>
      ))}
    </div>
  );
}
