import FirebaseFirestoreService from "../libs/firebase/FirebaseFirestoreService";
import FirebaseFirestore from "@firebase/firestore-types";
import { TDocument } from "./types";
import { TQueryDocument } from "@libs/firebase/Firebase.types";
import { dateToString } from "utils/utils";
import { getLogsByEventId } from "./logs";
import { countFiles } from "./events";

const EVENTS_COLLECTION = "events";

const getRecentlyEvents = async () => {
  let queries = [];
  let perPage = undefined;

  const sevenDaysAgo = new Date();
  sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);

  const today = new Date();

  queries.push({
    field: "date",
    condition: ">=" as FirebaseFirestore.WhereFilterOp,
    value: dateToString(sevenDaysAgo) as string,
  });

  queries.push({
    field: "date",
    condition: "<" as FirebaseFirestore.WhereFilterOp,
    value: dateToString(today) as string,
  });

  perPage = 500;

  const args: TQueryDocument = {
    collection: EVENTS_COLLECTION,
    orderByField: "date",
    orderByDirection: "asc",
    queries: queries,
    perPage: perPage,
  };
  const response = await FirebaseFirestoreService.readDocuments(args);

  return response.docs.map((doc: TDocument) => {
    return {
      id: doc.id,
      ...doc.data(),
    };
  });
};

const getReleaseEvents = async () => {
  let queries = [];
  let perPage = undefined;

  const sevenDaysAgo = new Date();
  sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);

  const sevenDaysFromNow = new Date();
  sevenDaysFromNow.setDate(sevenDaysFromNow.getDate() + 7);

  queries.push({
    field: "releaseDate",
    condition: ">=" as FirebaseFirestore.WhereFilterOp,
    value: dateToString(sevenDaysAgo) as string,
  });

  queries.push({
    field: "releaseDate",
    condition: "<=" as FirebaseFirestore.WhereFilterOp,
    value: dateToString(sevenDaysFromNow) as string,
  });

  perPage = 500;

  const args: TQueryDocument = {
    collection: EVENTS_COLLECTION,
    orderByField: "releaseDate",
    orderByDirection: "asc",
    queries: queries,
    perPage: perPage,
  };
  const response = await FirebaseFirestoreService.readDocuments(args);

  return response.docs.map((doc: TDocument) => {
    return {
      id: doc.id,
      ...doc.data(),
    };
  });
};

const countFilesOrVideos = async (events: TDocument) => {
  return await Promise.all(
    events.map(async (event: any) => {
      const filesCount = await countFiles(event.id);
      return { ...event, filesCount, videosCount: undefined };
    })
  );
};

const getLogs = async (events: TDocument) => {
  return await Promise.all(
    events.map(async (event: any) => {
      const params = { id: event.id };
      const logs = await getLogsByEventId({ params });

      return { ...event, logs };
    })
  );
};

export const getHomeData = async () => {
  const recentlyEvents = await getRecentlyEvents();
  const recentlyEventsWithVideoCount = await countFilesOrVideos(recentlyEvents);
  const recentlyEventsWithVideosCountAndLogs = await getLogs(
    recentlyEventsWithVideoCount
  );

  const releaseEvents = await getReleaseEvents();
  const releaseEventsWithVideoCount = await countFilesOrVideos(releaseEvents);
  const releaseEventsWithVideosCountAndLogs = await getLogs(
    releaseEventsWithVideoCount
  );

  return {
    recentlyEvents: recentlyEventsWithVideosCountAndLogs,
    releaseEvents: releaseEventsWithVideosCountAndLogs,
  };
};
