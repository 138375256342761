import { TEvent, TUpload } from "@services/types";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useLoaderData, useNavigate, useParams } from "react-router-dom";
import { eventBanner, eventTitleFormatter } from "utils/event";
import * as DigitalOceanSpaces from "../../services/digital_ocean/spaces";
import eventTablePhoto from "../../assets/images/bg-customer-login.png";

type TEventExtended = TEvent & { uploads: TUpload[] };

const Home = () => {
  const event = useLoaderData() as TEventExtended;
  const eventTitle = eventTitleFormatter(event);
  const eventDateFormatted = dayjs(event.date).format("DD | MM | YYYY");
  const eventBannerUrl = eventBanner(event);
  const { eventCode } = useParams();
  const navigate = useNavigate();

  const [firstEventPhoto, setFirstEventPhoto] = useState<string | null>(null);

  const handleEventEditClick = () =>
    navigate(`/customer-panel/${eventCode}/edit`);

  const handleQRCodeClick = () =>
    navigate(`/customer-panel/${eventCode}/qrcode`);

  const handleGalleryClick = () =>
    navigate(`/customer-panel/${eventCode}/gallery`);

  useEffect(() => {
    const firstPhotoCheck = async () => {
      if (event.uploads.length > 0) {
        const thumbnailStorageKey = event.uploads[0].thumbnailStoragePath;
        const thumbnailPublicUrl = await DigitalOceanSpaces.getFileUrl(
          thumbnailStorageKey as string
        );
        setFirstEventPhoto(thumbnailPublicUrl);
      }
    };

    firstPhotoCheck();
  }, [event]);

  return (
    <div className="max-w-screen-xl w-full mx-auto px-10 pb-16">
      <div className="flex justify-between items-center gap-4 mb-10 md:mb-14 relative">
        <div className="grow hidden md:flex" />
        <div className="w-full relative md:absolute left-1/2 transform -translate-x-1/2">
          <h1 className="text-3xl text-center font-playfair-display font-normal">
            {eventTitle}
          </h1>
        </div>
        <div className="hidden md:flex justify-end">
          <div className="bg-primary-300 text-white py-2.5 px-5 rounded-full inline-block">
            <p className="font-century-gothic font-semibold text-sm">
              Código: {eventCode}
            </p>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-10 md:gap-6">
        {/* Event Card */}
        <div className="bg-white shadow-md rounded-lg h-[330px] md:h-[390px] w-full md:w-[330px] flex flex-col">
          <div className="bg-primary-300 rounded-t-lg py-2">
            <h2 className="text-white text-lg text-center font-century-gothic font-semibold">
              Seu Evento
            </h2>
          </div>
          <div className="grow flex flex-col items-center justify-center p-3">
            <div
              className={`w-full h-full rounded-lg bg-cover bg-center relative`}
              style={{ backgroundImage: `url(${eventBannerUrl})` }}
            >
              <div className="h-full flex flex-col justify-end text-center p-1.5 gap-3 relative z-10">
                <div className="flex flex-col items-start text-white">
                  <p className="text-xl font-semibold font-playfair-display">
                    {eventTitle}
                  </p>
                  <p className="text-xs font-century-gothic">
                    {eventDateFormatted}
                  </p>
                </div>
                <button
                  className="w-full bg-white/90 text-black font-century-gothic font-semibold text-sm py-2 px-6 rounded-lg"
                  onClick={handleEventEditClick}
                >
                  Editar evento
                </button>
              </div>
              <div className="absolute inset-0 bg-black opacity-20 rounded-lg"></div>
            </div>
          </div>
        </div>

        {/* QR Code Card */}
        <div className="bg-white shadow-md rounded-lg h-[330px] md:h-[390px] w-full md:w-[330px] flex flex-col">
          <div className="bg-primary-300 rounded-t-lg py-2">
            <h2 className="text-white text-lg text-center font-century-gothic font-semibold">
              QR Code e Cartão
            </h2>
          </div>
          <div className="grow flex flex-col items-center justify-center p-3">
            <div className="w-full h-full rounded-lg bg-[url('../../assets/images/event-card-model1.png')] bg-cover bg-center relative">
              <div className="h-full flex flex-col justify-end text-center p-1.5 gap-3 relative z-10">
                <button
                  className="w-full bg-white/90 text-black font-century-gothic font-semibold text-sm py-2 px-6 rounded-lg"
                  onClick={handleQRCodeClick}
                >
                  Ver mais
                </button>
              </div>
              <div className="absolute inset-0 bg-black opacity-20 rounded-lg"></div>
            </div>
          </div>
        </div>

        {/* Videos Card */}
        <div className="bg-white shadow-md rounded-lg h-[330px] md:h-[390px] grow w-full md:w-[330px] flex flex-col">
          <div className="bg-primary-300 rounded-t-lg py-2">
            <h2 className="text-white text-lg text-center font-century-gothic font-semibold">
              Galeria da Festa
            </h2>
          </div>
          <div className="grow flex flex-col items-center justify-center p-3">
            <div
              className="w-full h-full rounded-lg bg-cover bg-center relative"
              style={{
                backgroundImage: `url(${firstEventPhoto || eventTablePhoto})`,
              }}
            >
              <div className="h-full flex flex-col justify-end text-center p-1.5 gap-3 relative z-10">
                <button
                  className="w-full bg-white/90 text-black font-century-gothic font-semibold text-sm py-2 px-6 rounded-lg"
                  onClick={handleGalleryClick}
                >
                  Ver galeria
                </button>
              </div>
              <div className="absolute inset-0 bg-black opacity-20 rounded-lg"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
